import React from 'react'
import { safeCredentials, handleErrors } from '@utils/fetch';

import { Form, Icon, Input, Button, Result } from 'antd';

import './login.scss'

class Login extends React.Component {
  state = {
    success: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        fetch('/api/internal/request_login', safeCredentials({
          method: 'POST',
          body: JSON.stringify({
            email: values.email
          })
        }))
        .then(handleErrors)
        .then((data) => {
        });

        // alert(`We have sent your login link to ${values.email}`)
        // message.success(`We have sent your login link to ${values.email}`);
        this.setState({success: true});
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { success } = this.state;

    return (
      <div className="container pt-5">
        <h1 className="text-primary text-center mt-4"><img src="logo.png" width="225px" height="auto"></img></h1>

        { !success && (
          <Form onSubmit={this.handleSubmit} className="bg-white shadow-sm rounded mt-5 p-3 mx-auto" style={{ maxWidth: '330px' }}>
            <p className="text-center text-dark pt-3 pb-3 mb-2">Welcome back!</p>
            <Form.Item className="mb-3">
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input your email!' }],
              })(
                <Input size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
              )}
            </Form.Item>
            <Form.Item className="mb-0">
              <Button type="primary" htmlType="submit" size="large" block>
                Sign in to your account
              </Button>
              or <a href="/register">register now!</a>
            </Form.Item>
          </Form>
        )}

        { success && (
          <div className="container pt-5">
            <Result
              status="success"
              title="We have sent your login link to your email address"
              subTitle="If you don't see the email in your inbox, please check the spam box. If you need further help, please kindly email us at help@emailrefinery.com"
              className="bg-white"
              extra={[
                <a href="/login">
                  <Button type="primary" key="console">
                    Sign in again
                  </Button>
                </a>,
                <a href="/register"><Button key="buy">Register</Button></a>,
              ]}
            />
          </div>
        )}

      </div>
    )
  }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(Login);

export default WrappedLoginForm;
